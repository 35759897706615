import React from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import getPath from '../../../utils/getPath';

export const NextLinkPropTypes = {
  className: PropTypes.string,
  as: PropTypes.string,
  href: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  legacyBehavior: PropTypes.bool
};

const WrappedLinkPropTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  hasMenu: PropTypes.bool
};

const WrappedLink = React.forwardRef(
  ({ className, href, children, onClick, hasMenu, ...linkProps }, ref) => {
    return (
      // eslint-disable-next-line jsx-a11y/role-supports-aria-props
      <a
        href={href}
        onClick={onClick}
        ref={ref}
        aria-haspopup={hasMenu}
        aria-label={`Visit ${children.props.linkText} page`}
        className={className}
        {...linkProps}
      >
        {children}
      </a>
    );
  }
);

WrappedLink.propTypes = WrappedLinkPropTypes;

WrappedLink.defaultProps = {
  href: null,
  onClick: null,
  className: null,
  hasMenu: null
};

function NextLink({ className, as, href, children, legacyBehavior, ...linkProps }) {
  const { lang } = useTranslation();
  const updatedAs = as ? getPath({ path: as, locale: lang }) : null;
  const updatedHref = getPath({ path: href, locale: lang });
  return (
    <Link legacyBehavior={legacyBehavior || true} passHref href={updatedHref} as={updatedAs}>
      <WrappedLink className={className} {...linkProps}>
        {children}
      </WrappedLink>
    </Link>
  );
}

NextLink.propTypes = NextLinkPropTypes;

NextLink.defaultProps = {
  as: null,
  className: null,
  legacyBehavior: true
};

export default React.memo(NextLink);
